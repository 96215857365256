import { DESTROY_SESSION, GET_PLANS, IS_PAID_SUBSCRIPTION, IS_TRIAL_CONSUMED, IS_TRIAL_MEMBER,  UNREAD_COUNT, PRO_TEMPLATES, RECOMMENDED_PLAN, TRIAL_SUCCESS, UPGRADE_POPUP, USER_FEATURE, USER_PROFILE, USER_SESSION, USER_SUBSCRIPTION, FEEDBACK_POPUP, FEEDBACK_CHECK_401 } from '../types/types'
import service from '../../utils/service'
import { apiEndpoints } from '../../utils/constants/apiEndpoints'
import { localStorageKeys } from '../../utils/constants/localStorageKeys'
import { clearLocalStorage, setLocalStorageItem } from '../../utils/helperFunctions/localStorage'
import { routesConstants } from '../../utils/constants/routes'

export const getUserInfo = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiEndpoints.ACCOUNTS_ME).then((res) => {
            setLocalStorageItem(localStorageKeys.PROFILE, JSON.stringify(res.content.profile))
            dispatch({
                type: USER_PROFILE,
                payload: res.content.profile
            })
            dispatch({
                type: IS_PAID_SUBSCRIPTION,
                payload: res?.content?.profile?.is_pro_member
            })
            dispatch({
                type: IS_TRIAL_CONSUMED,
                payload: res?.content?.profile?.is_trial_action_taken
            })
            dispatch({
                type: IS_TRIAL_MEMBER,
                payload: res?.content?.profile?.is_trial_member
            })
            dispatch(getUnreadConvo())
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const updateUserDetails = (res) => dispatch => {
    setLocalStorageItem(localStorageKeys.PROFILE, JSON.stringify(res.content.profile))
    dispatch({
        type: USER_PROFILE,
        payload: res.content.profile
    })
    dispatch({
        type: IS_PAID_SUBSCRIPTION,
        payload: res?.content?.profile?.is_pro_member
    })
    dispatch({
        type: IS_TRIAL_CONSUMED,
        payload: res?.content?.profile?.is_trial_action_taken
    })
    dispatch({
        type: IS_TRIAL_MEMBER,
        payload: res?.content?.profile?.is_trial_member
    })
}

export const getSubscription = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiEndpoints.SUBSCRIPTION).then((res) => {
            setLocalStorageItem(localStorageKeys.SUBSCRIPTION, JSON.stringify(res.content.subscription))
            dispatch({
                type: USER_SUBSCRIPTION,
                payload: res.content.subscription
            })
            resolve(res)
        })
            .catch((err) => {
                reject(err)
            })
    })
}


export const getPlans = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiEndpoints.PLANS).then((res) => {
            setLocalStorageItem(localStorageKeys.PLANS, JSON.stringify(res.content.plans))
            let { content: plans } = res
            let recom = plans && plans.plans.filter(item => item.is_recommended == true)[0]
            dispatch({
                type: RECOMMENDED_PLAN,
                payload: recom
            })
            dispatch({
                type: GET_PLANS,
                payload: res.content.plans
            })
            resolve(res)
        })
    })
}

export const getFeatures = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiEndpoints.FEATURES).then((res) => {
            dispatch({
                type: USER_FEATURE,
                payload: res.content.features
            })
            resolve(res)
        })
    })
}

export const upgradeSubscription = (id, type, is_trial, history) => dispatch => {
    let obj = {
        plan_id: id,
        recurring_type: type,
        is_trial : false
    }
    setLocalStorageItem(localStorageKeys.UPGRADE_PLAN, JSON.stringify(obj))
    setTimeout(() => {
        history.push(routesConstants.CHECKOUT)
    }, 250)
}

export const toggleUpgradePopup = (type) => (dispatch, getState) => {
    let { userDetailsReducers: { upgradePopup } } = getState()
    dispatch({
        type: UPGRADE_POPUP,
        payload: type ? false : !upgradePopup
    })
}

export const toggleTrialSuccess = (type) => (dispatch, getState) => {
    let { userDetailsReducers: { trialSuccess } } = getState()
    dispatch({
        type: TRIAL_SUCCESS,
        payload: type ? false : !trialSuccess
    })
}

export const toggleFeedback = (data) => (dispatch) => {
    dispatch({
        type: FEEDBACK_POPUP,
        payload: data
    })
}

export const getProTemplates = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        service.get(apiEndpoints.PRO_TEMPLATES).then((res) => {
            dispatch({
                type: PRO_TEMPLATES,
                payload: JSON.parse(res?.content?.feature?.value)
            })
            resolve(res)
        })
    })
}

export const getUnreadConvo = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        service.get(`${apiEndpoints.CHATBOT_CONVERSATIONS}?is_read=false`, {progress:false}).then((res) => {
            dispatch({
                type: UNREAD_COUNT,
                payload: res?.content?.count
            })
            resolve(res)
        })
    })
}

export const destroySession = () => (dispatch) => {
    dispatch({
        type: DESTROY_SESSION,
    })
}

export const checkFeedback401 = (data) => (dispatch) => {
    dispatch({
        type: FEEDBACK_CHECK_401,
        payload : data
    })
}

export const checkUserSession = (data) => (dispatch) => {
    if(data){
        clearLocalStorage()
    }
    dispatch({
        type: USER_SESSION,
        payload : data
    })
}